import React from 'react';
import getErrorMessage from './getErrorMessage';

const renderErrors = ({ touched, error }, validateMessages) => {
    if (touched && error) {
        return (
            <div className="error-wrapper">
                <span>{getErrorMessage(error, validateMessages)}</span>
            </div>
        )
    }

    return null;
}

export default renderErrors;