import React, { Component } from 'react';
import { withRouter } from 'react-router';

import styles from './errorBoundary.module.scss'

class ErrorBoundary extends Component{

    constructor(props){
        super(props);

        this.state = {
            hasError: false,
        }

        this.onClickButton = this.onClickButton.bind(this);
    }

    componentDidCatch(error, info){
        if(error.data || error.request){
            this.setState({
                hasError: 'server'
            })
        }else{
            this.setState({
                hasError: 'code'
            })
        }
    }

    onClickButton(){
        window.location.reload();
    }

    render(){
        if(this.state.hasError){
            return (
                <div className={styles.errorPage}>
                    {this.state.hasError === 'server' &&
                        <p>Oops! Server error occurred, please try again</p>
                    }
                    {this.state.hasError === 'code' &&
                        <p>Oops! Something has gone wrong, please try again</p>
                    }
                    <button type="button" onClick={this.onClickButton} className="btn btn-primary">Reload</button>
                </div>
            )
        }

        return this.props.children;
    }
}

export default withRouter(ErrorBoundary);
