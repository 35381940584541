import {match} from '@/utils/match';

const INITIAL_STATE = {
    wedding: {},
    mapPoints: [],
};

export default (state = INITIAL_STATE, {type, payload}) => match(type, {
    FETCH_WEDDING: () => ({
        ...state,
        wedding: payload,
    }),
    FETCH_MAP_POINTS: () => ({
        ...state,
        mapPoints: payload.organizer_map_points,
    }),
}, state);


