import React from "react"

const SvgComponent = props => (
    <svg
        width="18px"
        height="18px"
        viewBox="0 0 18 18">
        <path fillRule="evenodd" fill="rgb(109, 105, 102)"
              d="M18.003,0.556 L17.442,-0.005 L8.999,8.437 L0.557,-0.005 L-0.004,0.556 L8.438,8.998 L-0.004,17.441 L0.557,18.002 L8.999,9.558 L17.442,18.002 L18.003,17.441 L9.560,8.998 L18.003,0.556 Z"/>
    </svg>
)

export default SvgComponent


