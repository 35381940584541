import React, {Component} from 'react';
import {NavLink} from 'react-router-dom';

import styles from './mobileMenu.module.scss'

import {paths} from '@/constants/config';
import classNames from "classnames";

class mobileMenu extends Component {


    render() {
        const { active, headerDark, headerLight } = this.props;
        return (
            <div className={
                classNames(
                    styles.mobileMenu,
                    'mobileMenu',
                    {[styles.active]:active},
                    {[styles.headerDark]:headerDark},
                    {[styles.headerLight]:headerLight}
                    )}>
                <nav className={styles.navbar}>
                    <ul className={styles.navbarNav}>
                        <li className={styles.navItem}>
                            <NavLink
                                exact
                                to={paths.HOME}
                                activeClassName={styles.active}
                            >
                                Home
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to={paths.ORGANIZER}
                                activeClassName={styles.active}
                            >
                                Organizator
                            </NavLink>
                        </li>
                        <li className={styles.navItem}>
                            <NavLink
                                to={paths.OFFERS}
                                activeClassName={styles.active}
                            >
                                oferty
                            </NavLink>
                        </li>
                        <li className={styles.navItem}>
                            <NavLink
                                to={paths.ADVERTS}
                                activeClassName={styles.active}
                            >
                                Ogłoszenia
                            </NavLink>
                        </li>
                        <li className={styles.navItem}>
                            <NavLink
                                to={paths.INSPIRATIONS}
                                activeClassName={styles.active}
                            >
                                Inspiracje
                            </NavLink>
                        </li>
                    </ul>
                </nav>
            </div>
        )
    }
}

export default mobileMenu;