import {match} from '../utils/match';

const INITIAL_STATE = {
    loginModal: {
        isShowing: false,
        loginModal: true
    },
    registrationModal: {
        isShowing: false,
    },
    addGuestModal: {
        isShowing: false
    },
    addGuestGroupModal: {
        isShowing: false
    },
    editWeddingModal: {
        isShowing: false
    }
};

export default (state = INITIAL_STATE, {type, payload}) => match(type, {
    SHOW_LOGIN_MODAL: () => ({
        ...state,
        loginModal: {
            isShowing: true,
            loginModal: payload
        },
    }),
    HIDE_LOGIN_MODAL: () => ({
        ...state,
        loginModal: {
			isShowing: false
        },
    }),
    SHOW_ADDGUEST_MODAL: () => ({
        ...state,
        addGuestModal: {
            isShowing: true
        },
    }),
    HIDE_ADDGUEST_MODAL: () => ({
        ...state,
        addGuestModal: {
            isShowing: false
        },
    }),
    SHOW_ADDGUESTGROUP_MODAL: () => ({
        ...state,
        addGuestGroupModal: {
            isShowing: true
        },
    }),
    HIDE_ADDGUESTGROUP_MODAL: () => ({
        ...state,
        addGuestGroupModal: {
            isShowing: false
        },
    }),
    SHOW_EDITWEDDING_MODAL: () => ({
        ...state,
        editWeddingModal: {
            isShowing: true
        },
    }),
    HIDE_EDITWEDDING_MODAL: () => ({
        ...state,
        editWeddingModal: {
            isShowing: false
        },
    }),
}, state);
