import {match} from '@/utils/match';

const INITIAL_STATE = {
    guests: [],
    guest: {},
    group: [],
    details: {}
};

export default (state = INITIAL_STATE, {type, payload}) => match(type, {
    FETCH_GUESTS: () => ({
        ...state,
        guests: payload.guest_list_categories,
    }),
    FETCH_GUESTS_INFO: () => ({
        ...state,
        details: payload,
    }),
    FETCH_GUEST: () => ({
        ...state,
        guest: payload,
    }),
    FETCH_GUEST_CATEGORIES: () => ({
        ...state,
        group: payload,
    }),
}, state);


