import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import store from '@/store';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';

class Modal extends Component {

    componentDidMount() {
        this.modalTarget = document.createElement('div');
        this.modalTarget.className = `modal fade show modal-active ${this.props.extraClass}`;
        document.body.appendChild(this.modalTarget);
        document.body.classList.add('modal-open');

        this._render();
    }

    componentDidUpdate() {
        this._render();
    }

    componentWillUnmount() {
        ReactDOM.unmountComponentAtNode(this.modalTarget);
        document.body.removeChild(this.modalTarget);
        document.body.classList.remove('modal-open')
    }

    _render() {
        ReactDOM.render(
            <Provider store={store}>
                {this.props.children}
            </Provider>,
            this.modalTarget
        )
    }

    render() {
        return <noscript />
    }
}

export default Modal;

Modal.propTypes = {
    extraClass: PropTypes.string
}

Modal.defaultProps = {
    extraClass: ''
};