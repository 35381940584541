import React, {Component, Fragment} from 'react';
import classNames from 'classnames';
import {NavLink, Link} from 'react-router-dom';
import {paths} from '@/constants/config';
import styles from './loginMenu.module.scss'
import {bindActionCreators} from "redux";
import {connect} from "react-redux";

import * as modalsActions from '@/actions/modals';

class loginMenu extends Component {

    handleShowLoginModal() {
        this.props.showLoginModal();
    }

    render() {
        const {active, headerDark, headerLight, partner} = this.props;
        return (
            <div className={
                classNames(styles.loginMenu, 'loginMenu',
                    {[styles.active]: active},
                    {[styles.headerDark]: headerDark},
                    {[styles.headerLight]: headerLight})
            }
            >
                <nav className={styles.navbar}>
                    <ul className={styles.navbarNav}>
                        {partner ?
                            <Fragment>
                                <li className={styles.navItem}>
                                    <NavLink to={paths.PARTNER_LOGIN}>
                                        Rejestracja
                                    </NavLink>
                                </li>
                                <li className={styles.navItem}>
                                    <NavLink to={paths.PARTNER_LOGIN}>
                                        Logowanie
                                    </NavLink>
                                </li>
                            </Fragment>
                            :
                            <Fragment>
                                <li className={styles.navItem}>
                                    <Link to={{pathname: '/registration',state: {modal: true}}}>
                                        Rejestracja
                                    </Link>
                                </li>
                                <li className={styles.navItem}>
                                    <Link to={{pathname: '/login',state: {modal: true}}}>
                                        Logowanie
                                    </Link>
                                </li>
                            </Fragment>
                        }
                    </ul>
                </nav>
            </div>
        )
    }
}


const mapDispatchToProps = dispatch => ({
    showLoginModal: bindActionCreators(modalsActions.showLoginModal, dispatch),
})

export default connect(null, mapDispatchToProps)(loginMenu);