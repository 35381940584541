import {match} from '../utils/match';

const INITIAL_STATE = {
    userInfo: {},
    jwtDecode: {},
    userInfoDetails: {},
    isPending: false,
    isAuthenticated:false,
};

export default (state = INITIAL_STATE, {type, payload}) => match(type, {
    GET_USER_DETAILS: () => ({
        ...state,
        isPending: true
    }),
    GET_USER_DETAILS_SUCCESS: () => ({
        ...state,
        isPending: false,
        userInfoDetails: payload
    }),
    GET_USER: () => ({
        ...state,
        userInfo: payload.data,
        jwtDecode: payload.jwtDecode,
        isAuthenticated:true,
    }),
    CREATE_USER: () => ({
        ...state,
        userInfo: payload.data,
        jwtDecode: payload.jwtDecode,
    }),

    LOGOUT: () => ({
        userInfo: {},
        jwtDecode: {},
        userInfoDetails: {},
        isPending: false,
        isAuthenticated:false,
    })
}, state);


