import {match} from '@/utils/match';

const INITIAL_STATE = {
    categories: [],
    isPending: false
};

export default (state = INITIAL_STATE, {type, payload}) => match(type, {
    GET_CATEGORIES: () => ({
        ...state,
        isPending: true
    }),
    GET_CATEGORIES_SUCCESS: () => ({
        ...state,
        isPending: false,
        categories: payload.categories
    }),
}, state);


