import React from "react";

const SvgComponent = () => (
    <svg
        viewBox="0 0 19 24">
        <path fillRule="evenodd"  fill="rgb(255, 255, 255)"
              d="M15.878,22.000 L3.122,22.000 C1.400,22.000 -0.000,20.678 -0.000,19.054 L-0.000,11.093 C-0.000,9.469 1.400,8.148 3.122,8.148 L15.545,8.148 L15.545,5.907 C15.545,3.099 12.834,0.814 9.500,0.814 C6.166,0.814 3.454,3.099 3.454,5.907 C3.454,6.132 3.262,6.315 3.023,6.315 C2.784,6.315 2.591,6.132 2.591,5.907 C2.591,2.650 5.690,-0.000 9.500,-0.000 C13.309,-0.000 16.409,2.650 16.409,5.907 L16.409,8.194 C17.878,8.432 19.000,9.640 19.000,11.093 L19.000,19.054 C19.000,20.678 17.600,22.000 15.878,22.000 ZM18.136,11.093 C18.136,9.918 17.123,8.962 15.878,8.962 L3.122,8.962 C1.877,8.962 0.864,9.918 0.864,11.093 L0.864,19.054 C0.864,20.229 1.877,21.184 3.122,21.184 L15.878,21.184 C17.123,21.184 18.136,20.229 18.136,19.054 L18.136,11.093 ZM9.500,17.111 C8.547,17.111 7.773,16.380 7.773,15.481 L7.773,13.037 C7.773,12.138 8.547,11.407 9.500,11.407 C10.453,11.407 11.227,12.138 11.227,13.037 L11.227,15.481 C11.227,16.380 10.453,17.111 9.500,17.111 ZM10.364,13.037 C10.364,12.587 9.976,12.222 9.500,12.222 C9.024,12.222 8.636,12.587 8.636,13.037 L8.636,15.481 C8.636,15.931 9.024,16.296 9.500,16.296 C9.976,16.296 10.364,15.931 10.364,15.481 L10.364,13.037 Z"/>
    </svg>
);

export default SvgComponent;