import React from "react"

const SvgComponent = props => (
    <svg
        width="27px" height="22px">
        <path fillRule="evenodd"  fill="rgb(48, 48, 48)"
              d="M25.625,1.173 C24.579,1.786 23.421,2.232 22.190,2.473 C21.203,1.431 19.800,0.781 18.244,0.781 C15.257,0.781 12.838,3.180 12.838,6.138 C12.838,6.557 12.885,6.966 12.978,7.358 C8.484,7.135 4.500,5.001 1.834,1.760 C1.368,2.551 1.103,3.471 1.103,4.454 C1.103,6.312 2.058,7.953 3.507,8.912 C2.622,8.883 1.788,8.641 1.059,8.239 L1.059,8.306 C1.059,10.902 2.923,13.066 5.395,13.560 C4.942,13.682 4.464,13.748 3.970,13.748 C3.621,13.748 3.284,13.713 2.954,13.651 C3.641,15.779 5.637,17.328 8.002,17.373 C6.153,18.808 3.821,19.665 1.288,19.665 C0.851,19.665 0.421,19.639 -0.002,19.591 C2.391,21.111 5.232,21.998 8.285,21.998 C18.230,21.998 23.667,13.835 23.667,6.754 L23.649,6.060 C24.709,5.309 25.628,4.364 26.352,3.291 C25.382,3.716 24.341,4.006 23.247,4.135 C24.363,3.473 25.219,2.421 25.625,1.173 Z"/>
    </svg>
)

export default SvgComponent
