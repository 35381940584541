import React from "react";

const SvgComponent = () => (
    <svg
	 			viewBox="0 0 59.414 59.414">
				<g>
					<polygon fill="#7e7e7e" points="15.561,59.414 14.146,58 42.439,29.707 14.146,1.414 15.561,0 45.268,29.707 	"/>
				</g>
		</svg>
);

export default SvgComponent;