import { modals as types } from '@/constants/actionsNames'

export const showLoginModal = (login) => dispatch => {
    dispatch({
        type: types.SHOW_LOGIN_MODAL,
        payload: login
    })
}


export const hideLoginModal = () => dispatch => {
    dispatch({
        type: types.HIDE_LOGIN_MODAL,
    })
}

export const showAddGuestModal = () => dispatch => {
    dispatch({
        type: types.SHOW_ADDGUEST_MODAL,
    })
}
export const hideAddGuestModal = () => dispatch => {
    dispatch({
        type: types.HIDE_ADDGUEST_MODAL,
    })
}
export const showAddGuestGroupModal = () => dispatch => {
    dispatch({
        type: types.SHOW_ADDGUESTGROUP_MODAL,
    })
}
export const hideAddGuestGroupModal = () => dispatch => {
    dispatch({
        type: types.HIDE_ADDGUESTGROUP_MODAL,
    })
}
export const showEditWeddingModal = () => dispatch => {
    dispatch({
        type: types.SHOW_EDITWEDDING_MODAL,
    })
}
export const hideEditWeddingModal = () => dispatch => {
    dispatch({
        type: types.HIDE_EDITWEDDING_MODAL,
    })
}
