import {match} from '@/utils/match';

const INITIAL_STATE = {
    budget: {},
    budgetCategories:{},
    budgetCategory:{},
    isPending: false,
    showBudgetCategory:false,
};

export default (state = INITIAL_STATE, {type, payload}) => match(type, {

    FETCH_BUDGET_CATEGORIES: () => ({
        ...state,
        budgetCategories: payload
    }),
    
    FETCH_BUDGET_CATEGORY: () => ({
        ...state,
        budgetCategory: payload
    }),
    SHOW_NEW_BUDGET_CATEGORY: () => ({
        ...state,
        showBudgetCategory:true,
    }),
    HIDE_NEW_BUDGET_CATEGORY: () => ({
        ...state,
        showBudgetCategory:false,
    }),
    FETCH_BUDGET_STATS: () => ({
        ...state,
        budget:payload,
    }),
}, state);