import { VALIDATION_MESSAGES } from '@/constants/config';

const getErrorMessage = (error, validateMessages) => {
    let errorMessage = (validateMessages && error.type in validateMessages) ? validateMessages[error.type] : error.defaultMessage;
    if (!errorMessage) {
        errorMessage = VALIDATION_MESSAGES['default']
    }
    return errorMessage;
}

export default getErrorMessage;