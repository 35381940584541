import React, {Component} from 'react';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Loadable from 'react-loadable';

import {paths} from '@/constants/config';

import ScrollTop from '@/containers/ScrollTop';
import BodyClasses from '@/containers/BodyClasses';
import Preloader from '@/components/html/Preloader';
import authRole from '@/components/hoc/auth/authRole';
// import loginCheck from '@/components/hoc/loginCheck/loginCheck';

const Home = Loadable({
    loader: () => import('@/containers/pages/Home'),
    loading: () => <Preloader active={false}/>,
});

const Inspirations = Loadable({
    loader: () => import('@/containers/pages/Inspirations'),
    loading: () => <Preloader active={false}/>,
});

const Inspiration = Loadable({
    loader: () => import('@/containers/pages/Inspiration'),
    loading: () => <Preloader active={false}/>,
});
const CategoryInspirations = Loadable({
    loader: () => import('@/containers/pages/CategoryInspirations'),
    loading: () => <Preloader active={false}/>,
});

const Offers = Loadable({
    loader: () => import('@/containers/pages/Offers'),
    loading: () => <Preloader active={false}/>,
});

const OrganizerSavedOffersCategories = Loadable({
    loader: () => import('@/containers/organizer/SavedOffersCategories'),
    loading: () => <Preloader active={false}/>,
});

const OrganizerSavedOffersCategorySingle = Loadable({
    loader: () => import('@/containers/organizer/SavedOffersCategorySingle'),
    loading: () => <Preloader active={false}/>,
});

const OrganizerTasksList = Loadable({
    loader: () => import('@/containers/organizer/TasksList'),
    loading: () => <Preloader active={false} />,
});

const OrganizerOffers = Loadable({
    loader: () => import('@/containers/organizer/Offers'),
    loading: () => <Preloader active={false} />,
});

const OrganizerAddOffer = Loadable({
    loader: () => import('@/containers/organizer/AddOffer'),
    loading: () => <Preloader active={false} />,
});

const OrganizerEditOffer = Loadable({
    loader: () => import('@/containers/organizer/EditOffer'),
    loading: () => <Preloader active={false} />,
});

const GuestList = Loadable({
    loader: () => import('@/containers/organizer/GuestList'),
    loading: () => <Preloader active={false} />,
});
const Invitations = Loadable({
    loader: () => import('@/containers/organizer/Invitations'),
    loading: () => <Preloader active={false} />,
});
const Reminders = Loadable({
    loader: () => import('@/containers/organizer/Reminders'),
    loading: () => <Preloader active={false} />,
});
const OfferSinglePro = Loadable({
    loader: () => import('@/containers/pages/Offers/OfferPro'),
    loading: () => <Preloader active={false}/>,
});
const OfferSingleStandard = Loadable({
    loader: () => import('@/containers/pages/Offers/OfferStandard'),
    loading: () => <Preloader active={false} />,
});

const Advert = Loadable({
    loader: () => import('@/containers/pages/Adverts/Advert'),
    loading: () => <Preloader active={false} />,
});

const Adverts = Loadable({
    loader: () => import('@/containers/pages/Adverts'),
    loading: () => <Preloader active={false} />,
});

const Dashboard = Loadable({
    loader: () => import('@/containers/organizer/Dashboard'),
    loading: () => <Preloader active={false} />,
});

const Site = Loadable({
    loader: () => import('@/containers/pages/Site'),
    loading: () => <Preloader active={false}/>,
});

const SiteEdit = Loadable({
    loader: () => import('@/containers/organizer/SiteEdit'),
    loading: () => <Preloader active={false}/>,
});

const OrganizerSingle = Loadable({
    loader: () => import('@/containers/pages/Organizer/OrganizerSingle'),
    loading: () => <Preloader active={false}/>,
});

const Organizer = Loadable({
    loader: () => import('@/containers/pages/Organizer'),
    loading: () => <Preloader active={false}/>,
});

const PartnerLogin = Loadable({
    loader: () => import('@/containers/partner/pages/PartnerLogin'),
    loading: () => <Preloader active={false}/>,
});

const Partner = Loadable({
    loader: () => import('@/containers/partner/pages/Home'),
    loading: () => <Preloader active={false}/>,
});

const PartnerDashboard = Loadable({
    loader: () => import('@/containers/partner/pages/Dashboard'),
    loading: () => <Preloader active={false}/>,
});

const PartnerMessages = Loadable({
    loader: () => import('@/containers/partner/pages/Messages'),
    loading: () => <Preloader active={false}/>,
});

const PartnerMessageMobile = Loadable({
    loader: () => import('@/containers/partner/pages/MessageMobile'),
    loading: () => <Preloader active={false}/>,
});

const PartnerOffers = Loadable({
    loader: () => import('@/containers/partner/pages/Offers'),
    loading: () => <Preloader active={false}/>,
});

const PartnerOffersPro = Loadable({
    loader: () => import('@/containers/partner/pages/Offers/OfferPro'),
    loading: () => <Preloader active={false}/>,
});

const PartnerOffersStandard = Loadable({
    loader: () => import('@/containers/partner/pages/Offers/OfferStandard'),
    loading: () => <Preloader active={false}/>,
});

const Login = Loadable({
    loader: () => import('@/components/modals/LoginModal'),
    loading: () => <Preloader active={true}/>,
});

const Budget = Loadable({
    loader: () => import('@/containers/organizer/Budget'),
    loading: () => <Preloader active={false}/>,
});

const AddOffer = Loadable({
    loader: () => import('@/containers/partner/pages/AddOffer'),
    loading: () => <Preloader active={false}/>,
});

const Settings = Loadable({
    loader: () => import('@/containers/partner/pages/Settings'),
    loading: () => <Preloader active={false}/>,
});

const ChangeToPro = Loadable({
    loader: () => import('@/containers/partner/pages/ChangeToPro'),
    loading: () => <Preloader active={false}/>,
});
//
const Messages = Loadable({
    loader: () => import('@/containers/partner/pages/Messages'),
    loading: () => <Preloader active={false}/>,
});
//
// const Message = Loadable({
//     loader: () => import('@/containers/partner/pages/MessageMobile'),
//     loading: () => <Preloader active={false}/>,
// });
// //
// const OrganizerMessages = Loadable({
//     loader: () => import('@/containers/organizer/Messages'),
//     loading: () => <Preloader active={false}/>,
// });
//
// const OrganizerMessage = Loadable({
//     loader: () => import('@/containers/organizer/MessageMobile'),
//     loading: () => <Preloader active={false}/>,
// });

class App extends Component {

    previousLocation = this.props.location;

    componentWillUpdate(nextProps) {
        const { location } = this.props;
        // set previousLocation if props.location is not modal
        if (
            nextProps.history.action !== "POP" &&
            (!location.state || !location.state.modal)
        ) {
            this.previousLocation = this.props.location;
        }
    }

    render() {

        const { location } = this.props;
        const isModal = !!(
            location.state &&
            location.state.modal &&
            this.previousLocation !== location
        ); // not initial render

        return (
            <ScrollTop>
                <BodyClasses>
                    <Switch location={isModal ? this.previousLocation : location}>
                        {/* niezalogowany */}
                        <Route exact path={paths.ADVERT} component={Advert} />
                        <Route exact path={paths.ADVERTS} component={Adverts} />
                        <Route exact path={paths.CATEGORYINSPIRATION} component={CategoryInspirations}/>
                        <Route exact path={paths.SINGLE_OFFER_PRO} component={OfferSinglePro}/>
                        <Route exact path={paths.SINGLE_OFFER_STANDARD} component={OfferSingleStandard} />
                        <Route exact path={paths.INSPIRATION} component={Inspiration} />
                        <Route exact path={paths.INSPIRATIONS} component={Inspirations} />
                        <Route exact path={paths.OFFERS} component={Offers}/>
                        {/* partner */}
                        <Route exact path={paths.PARTNER_DASHBOARD_MESSAGES} component={PartnerMessages} />
                        <Route exact path={paths.PARTNER_DASHBOARD_MESSAGE_MOBILE(':id')} component={PartnerMessageMobile} />
                        <Route exact path={paths.PARTNER_DASHBOARD} component={PartnerDashboard} />
                        <Route exact path={paths.PARTNER_OFFERS} component={PartnerOffers} />
                        <Route exact path={paths.PARTNER_OFFERS_PRO(':id')} component={PartnerOffersPro} />
                        <Route exact path={paths.PARTNER_OFFERS_STANDARD(':id')} component={PartnerOffersStandard} />
                        <Route exact path={paths.PARTNER_ADD_OFFER} component={AddOffer} />
                        <Route exact path={paths.PARTNER_LOGIN} component={PartnerLogin}/>
                        <Route exact path={paths.PARTNER_SETTINGS} component={Settings}/>
                        <Route exact path={paths.PARTNER_CHANGE_TO_PRO} component={ChangeToPro}/>
                        <Route exact path={paths.PARTNER} component={Partner} />
                        {/*<Route path={paths.PARTNER_DASHBOARD_MESSAGE_MOBILE(':id')} component={Message} />*/}
                        <Route path={paths.PARTNER_DASHBOARD_MESSAGES} component={Messages} />
                        {/* organizator */}
                        <Route exact path={paths.ORGANIZER} component={Organizer}/>
                        <Route exact path={paths.ORGANIZER_DASHBOARD} component={authRole(Dashboard,['organizer'])} />
                        <Route exact path={paths.ORGANIZER_BUDGET } component={authRole(Budget,['organizer'])} />
                        <Route exact path={paths.ORGANIZER_SAVED_OFFERS_CATEGORY_SINGLE(':id')} component={OrganizerSavedOffersCategorySingle} />
                        <Route exact path={paths.ORGANIZER_SAVED_OFFERS_CATEGORIES} component={OrganizerSavedOffersCategories} />
                        <Route exact path={paths.ORGANIZER_TASKS_LIST} component={OrganizerTasksList} />
                        <Route exact path={paths.ORGANIZER_ADD_OFFER} component={OrganizerAddOffer} />
                        <Route exact path={paths.ORGANIZER_EDIT_OFFER(':id')} component={OrganizerEditOffer} />
                        <Route exact path={paths.ORGANIZER_OFFERS} component={OrganizerOffers} />
                        <Route exact path={paths.ORGANIZER_GUESTS_INVITATIONS} component={Invitations} />
                        <Route exact path={paths.ORGANIZER_GUESTS_REMINDERS} component={Reminders} />
                        <Route exact path={paths.ORGANIZER_GUESTS} component={GuestList} />
                        {/*<Route path={paths.ORGANIZER_DASHBOARD_MESSAGE_MOBILE(':id')} component={OrganizerMessage} />*/}
                        {/*<Route path={paths.ORGANIZER_DASHBOARD_MESSAGES} component={OrganizerMessages} />*/}
                        
                        <Route exact path={paths.ORGANIZER_ID(':id')} component={OrganizerSingle}/>
                       
                        <Route exact path={paths.SITE_ID(':id')} component={() => <Site guest/>}/>
                        <Route exact path={paths.ORGANIZER_SITE_EDIT} component={SiteEdit}/>
                        <Route exact path={paths.SITE} component={Site}/>
                        <Route exact path={paths.HOME} component={Home}/>
                        {/*<Route component={Page404} />*/}
                    </Switch>
                    <Route
                        path="/(login|registration|resetpassword|newpassword)"
                        component={() => <Login
                            extraClass='modalXl'
                            login={location.pathname === '/login'}
                            reset={location.pathname === '/resetpassword'}
                            newPassword={location.pathname.startsWith("/newpassword")}
                            currentLocation={location.pathname}
                            previousLocation={this.previousLocation.pathname}
                        />}
                    />
                </BodyClasses>
            </ScrollTop>
        );
    }
}

const ModalApp = () => {
    return (
        <Router>
            <Route component={App} />
        </Router>
    )
}

export default ModalApp;
