import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as userActions from '@/actions/user';
import Unauthorized from '@/containers/Unauthorized';

export default function(ComposedComponent, authRoles) {

    class RequireAuth extends Component {
        constructor(props){
            super(props);
            let userRoles = this.props.getUserRoles();
            if(userRoles){
                if (!authRoles.length || authRoles.some(role => userRoles.includes(role))) {
                    this.state = {
                        isAuth: true,
                        isFetching: !this.props.isAuthenticated
                    }
                    return;
                }
            }else{
                this.state = {
                    isAuth: false
                }
            }
        }
        componentDidMount(){
            if (this.state.isAuth && !this.props.isAuthenticated){
                this.setState({
                    isAuth: true,
                    isFetching: false
                })
            }
        }
        render() {
            if (this.state.isAuth && this.props.isAuthenticated){
               return <ComposedComponent {...this.props} />
            }else{
                return <Unauthorized/>
            }
        }
    }

    const mapStateToProps = state => ({
        user: state.auth.userInfo,
        isAuthenticated: state.auth.isAuthenticated
    })

    const mapDispatchToProps = dispatch => ({
        getUserRoles: bindActionCreators(userActions.getUserRoles, dispatch)
    })

    return connect(mapStateToProps, mapDispatchToProps)(RequireAuth);
}