import React, {Component} from 'react';
import classNames from 'classnames';
import {Link} from 'react-router-dom';

import {Collapse} from 'react-collapse';

import IcoRightArrow from '@/icons/IcoRightArrow';
import IcoInstagram from '@/icons/IcoInstagram';
import IcoTwitter from '@/icons/IcoTwitter';
import IcoFacebook from '@/icons/IcoFacebook';

import styles from './footer.module.scss'


class Footer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            col1: true,
            col2: true,
            col3: true,
            col4: true,
            col5: true,
            year: ''
        }

    }

    componentDidMount() {
        window.addEventListener('resize', () => this.handleResize());
        this.handleResize();
        const date = new Date();
        const year = date.getFullYear();
        this.setState({year});
    }


    componentWillUnmount() {
        window.removeEventListener('resize', () => this.handleResize());
    }

    handleResize() {
        if (window.innerWidth > 991) {
            this.setState({
                col1: true,
                col2: true,
                col3: true,
                col4: true,
                col5: true,
            })
        } else {
            this.setState({
                col1: false,
                col2: false,
                col3: false,
                col4: false,
                col5: false,
            })
        }
        ;
    }

    haldleCol1() {
        this.setState(prevState => ({
            col1: !prevState.col1
        }));

        this.clearCol()
    }

    haldleCol2() {
        this.setState(prevState => ({
            col2: !prevState.col2
        }));

        this.clearCol()
    }

    haldleCol3() {
        this.setState(prevState => ({
            col3: !prevState.col3
        }));

        this.clearCol()
    }

    haldleCol4() {
        this.setState(prevState => ({
            col4: !prevState.col4
        }));

        this.clearCol()
    }

    haldleCol5() {
        this.setState(prevState => ({
            col5: !prevState.col5
        }));

        this.clearCol()
    }

    clearCol() {
        if (window.innerWidth > 991) {
            this.setState({
                col1: true,
                col2: true,
                col3: true,
                col4: true,
                col5: true,
            })
        }
    }

    render() {
        const {footerDark, footerLight, footerGuest, partner} = this.props;
        const {col1, col2, col3, col4, col5, year} = this.state;

        return (
            <footer
                className={classNames(
                    styles.footer,
                    {[styles.footerDark]: footerDark},
                    {[styles.footerLight]: footerLight},
                    {[styles.footerGuest]: footerGuest})}
            >
                {!partner && (
                    <div className={styles.footerTop}>
                        <div className="container">
                            {footerGuest ?
                                <div className={styles.content}>
                                    <Link to="/" className={styles.linkHome}>
                                        Vesele {year}
                                    </Link>
                                </div> :
                                <div>
                                    <section className={`section ${styles.section}`}>
                                        <h1 className={`h1 ${styles.h1}`}>
                                            Usługi na podstawie lokalizacji
                                            <span className={`icoRight ${styles.icoRight}`}>
                                <IcoRightArrow/>
                            </span>
                                        </h1>
                                        <div className={styles.footerMenu}>
                                            <div className="row">
                                                <div className="col-xs-12 col-lg">
                                                    <h3 className={styles.header} onClick={() => this.haldleCol1()}>
                                                        Sale weselne
                                                    </h3>
                                                    <Collapse isOpened={col1}>
                                                        <ul className={styles.list}>
                                                            <li className={styles.item}>Sale weselne <span
                                                                className={styles.city}>Warszawa</span></li>
                                                            <li className={styles.item}>Sale weselne <span
                                                                className={styles.city}>Kraków</span></li>
                                                            <li className={styles.item}>Sale weselne <span
                                                                className={styles.city}>Łódź</span></li>
                                                            <li className={styles.item}>Sale weselne <span
                                                                className={styles.city}>Wrocław</span></li>
                                                            <li className={styles.item}>Sale weselne <span
                                                                className={styles.city}>Poznań</span></li>
                                                            <li className={styles.item}>Sale weselne <span
                                                                className={styles.city}>Gdańsk</span></li>
                                                            <li className={styles.item}>Sale weselne <span
                                                                className={styles.city}>Szczecin</span></li>
                                                            <li className={styles.item}>Sale weselne <span
                                                                className={styles.city}>Bydgoszcz</span></li>
                                                            <li className={styles.item}>Sale weselne <span
                                                                className={styles.city}>Lublin</span></li>
                                                        </ul>
                                                    </Collapse>
                                                </div>
                                                <div className="col-xs-12 col-lg">
                                                    <h3 className={styles.header} onClick={() => this.haldleCol2()}>
                                                        Catering weselny
                                                    </h3>
                                                    <Collapse isOpened={col2}>
                                                        <ul className={styles.list}>
                                                            <li className={styles.item}>Catering weselny <span
                                                                className={styles.city}>Warszawa</span></li>
                                                            <li className={styles.item}>Catering weselny <span
                                                                className={styles.city}>Warszawa</span></li>
                                                            <li className={styles.item}>Catering weselny <span
                                                                className={styles.city}>Warszawa</span></li>
                                                            <li className={styles.item}>Catering weselny <span
                                                                className={styles.city}>Warszawa</span></li>
                                                            <li className={styles.item}>Catering weselny <span
                                                                className={styles.city}>Warszawa</span></li>
                                                            <li className={styles.item}>Catering weselny <span
                                                                className={styles.city}>Warszawa</span></li>
                                                            <li className={styles.item}>Catering weselny <span
                                                                className={styles.city}>Warszawa</span></li>
                                                        </ul>
                                                    </Collapse>
                                                </div>
                                                <div className="col-xs-12 col-lg">
                                                    <h3 className={styles.header} onClick={() => this.haldleCol3()}>
                                                        Transport weselny
                                                    </h3>
                                                    <Collapse isOpened={col3}>
                                                        <ul className={styles.list}>
                                                            <li className={styles.item}>Transport weselny <span
                                                                className={styles.city}>Warszawa</span></li>
                                                            <li className={styles.item}>Transport weselny <span
                                                                className={styles.city}>Warszawa</span></li>
                                                            <li className={styles.item}>Transport weselny <span
                                                                className={styles.city}>Warszawa</span></li>
                                                            <li className={styles.item}>Transport weselny <span
                                                                className={styles.city}>Warszawa</span></li>
                                                            <li className={styles.item}>Transport weselny <span
                                                                className={styles.city}>Warszawa</span></li>
                                                        </ul>
                                                    </Collapse>
                                                </div>
                                                <div className="col-xs-12 col-lg">
                                                    <h3 className={styles.header} onClick={() => this.haldleCol4()}>
                                                        Odzież weselna
                                                    </h3>
                                                    <Collapse isOpened={col4}>
                                                        <ul className={styles.list}>
                                                            <li className={styles.item}>Odzież weselna <span
                                                                className={styles.city}>Warszawa</span></li>
                                                            <li className={styles.item}>Odzież weselna <span
                                                                className={styles.city}>Warszawa</span></li>
                                                            <li className={styles.item}>Odzież weselna <span
                                                                className={styles.city}>Warszawa</span></li>
                                                            <li className={styles.item}>Odzież weselna <span
                                                                className={styles.city}>Warszawa</span></li>
                                                            <li className={styles.item}>Odzież weselna <span
                                                                className={styles.city}>Warszawa</span></li>
                                                            <li className={styles.item}>Odzież weselna <span
                                                                className={styles.city}>Warszawa</span></li>
                                                            <li className={styles.item}>Odzież weselna <span
                                                                className={styles.city}>Warszawa</span></li>
                                                            <li className={styles.item}>Odzież weselna <span
                                                                className={styles.city}>Warszawa</span></li>
                                                            <li className={styles.item}>Odzież weselna <span
                                                                className={styles.city}>Warszawa</span></li>
                                                        </ul>
                                                    </Collapse>
                                                </div>
                                                <div className="col-xs-12 col-lg">
                                                    <h3 className={styles.header} onClick={() => this.haldleCol5()}>
                                                        Floryści weselni
                                                    </h3>
                                                    <Collapse isOpened={col5}>
                                                        <ul className={styles.list}>
                                                            <li className={styles.item}>Floryści weselni <span
                                                                className={styles.city}>Warszawa</span></li>
                                                            <li className={styles.item}>Floryści weselni <span
                                                                className={styles.city}>Warszawa</span></li>
                                                            <li className={styles.item}>Floryści weselni <span
                                                                className={styles.city}>Warszawa</span></li>
                                                            <li className={styles.item}>Floryści weselni <span
                                                                className={styles.city}>Warszawa</span></li>
                                                            <li className={styles.item}>Floryści weselni <span
                                                                className={styles.city}>Warszawa</span></li>
                                                            <li className={styles.item}>Floryści weselni <span
                                                                className={styles.city}>Warszawa</span></li>
                                                            <li className={styles.item}>Floryści weselni <span
                                                                className={styles.city}>Warszawa</span></li>
                                                            <li className={styles.item}>Floryści weselni <span
                                                                className={styles.city}>Warszawa</span></li>
                                                            <li className={styles.item}>Floryści weselni <span
                                                                className={styles.city}>Warszawa</span></li>
                                                            <li className={styles.item}>Floryści weselni <span
                                                                className={styles.city}>Warszawa</span></li>
                                                        </ul>
                                                    </Collapse>
                                                </div>
                                            </div>
                                        </div>
                                    </section>
                                </div>
                            }
                        </div>
                    </div>
                )}
                {!footerGuest ?
                    <div className={styles.footerBottom}>
                        <div className="container">
                            <div className={styles.socialWrapp}>
                                <Link to="/" className={classNames(styles.facebook, styles.socialIco)}>
                                    <IcoFacebook/>
                                </Link>
                                <Link to="/" className={classNames(styles.twitter, styles.socialIco)}>
                                    <IcoTwitter/>
                                </Link>
                                <Link to="/" className={classNames(styles.instagram, styles.socialIco)}>
                                    <IcoInstagram/>
                                </Link>
                            </div>
                            <div className={styles.linksWrapp}>
                                <div className="row">
                                    <div
                                        className={classNames('col-md-12', 'col-lg-8', 'order-md-first', 'order-lg-last')}>
                                        <div className={styles.pagesLinks}>
                                            <ul className={styles.linksList}>
                                                <li>
                                                    <Link
                                                        to="/"
                                                    >
                                                        Kontakt
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link
                                                        to="/"
                                                    >
                                                        Regulamin serwisu
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link
                                                        to="/"
                                                    >
                                                        Polityka prywatności
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div
                                        className={classNames('col-md-12', 'col-lg-4', 'order-md-last', 'order-lg-first')}>
                                        <div className={styles.homeLink}>
                                            <Link
                                                to="/"
                                            >
                                                Vesele {year}.
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : null}
            </footer>
        )
    }
}

export default Footer;