import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import classNames from 'classnames';

// import validate from '../../../../shared/helpers/validations/admin/createNewCompanyExternal';
import FieldInput from '@/components/Fields/FieldInput/FieldInput';
import requiredValidator from '@/helpers/form/validators/required';
import emailValidator from '@/helpers/form/validators/email';

import styles from './resetForm.module.scss'

class resetForm extends Component {
    render() {
        const { handleSubmit } = this.props;
        return (
            <div>
                <form className="form" onSubmit={handleSubmit}>
                    <Field
                        name="email"
                        type="text"
                        component={FieldInput}
                        placeholder="E-mail"
                        formGroup
                        field="required"
                        validate={[requiredValidator, emailValidator]}
                    />
                    

                    <div className={classNames('text-center', styles.more)}>
                       Na podany adres email wyślemy link resetujący hasło 
                       
                       
                    </div>

                    <div className="clearfix">
                        <button type="submit" className={classNames('btn', 'btn-dark-pink', 'btn-block', 'btn-sm', styles.btn)}>
                            Resetuj hasło
                        </button>
                    </div>
                </form>
            </div>

        );
    }
}

const ResetPassword = reduxForm({
    form: 'ResetFormClass',
})(resetForm);


export default ResetPassword;
