import React from "react"

const SvgComponent = props => (
    <svg
        width="28px" height="28px">
        <path fillRule="evenodd"  fill="rgb(48, 48, 48)"
              d="M20.271,27.818 L7.725,27.818 C3.463,27.818 -0.003,24.384 -0.003,20.162 L-0.003,7.730 C-0.003,3.508 3.463,0.073 7.725,0.073 L20.271,0.073 C24.532,0.073 27.999,3.508 27.998,7.730 L27.998,20.162 C27.998,24.384 24.532,27.818 20.271,27.818 ZM25.514,7.730 C25.514,4.865 23.162,2.535 20.271,2.535 L7.725,2.535 C4.834,2.535 2.482,4.865 2.482,7.730 L2.482,20.162 C2.482,23.026 4.834,25.357 7.725,25.357 L20.271,25.357 C23.162,25.357 25.514,23.026 25.514,20.162 L25.514,7.730 ZM21.515,8.320 C21.037,8.320 20.567,8.127 20.228,7.792 C19.889,7.455 19.694,6.989 19.694,6.514 C19.694,6.039 19.889,5.572 20.228,5.238 C20.567,4.901 21.037,4.710 21.515,4.710 C21.996,4.710 22.466,4.901 22.804,5.238 C23.144,5.572 23.337,6.039 23.337,6.514 C23.337,6.989 23.144,7.455 22.804,7.792 C22.464,8.127 21.996,8.320 21.515,8.320 ZM13.998,21.095 C10.019,21.095 6.783,17.888 6.783,13.947 C6.783,10.003 10.019,6.797 13.998,6.797 C17.976,6.797 21.213,10.003 21.213,13.947 C21.213,17.888 17.976,21.095 13.998,21.095 ZM13.998,9.258 C11.389,9.258 9.268,11.361 9.268,13.946 C9.268,16.531 11.389,18.633 13.998,18.633 C16.606,18.633 18.729,16.531 18.729,13.946 C18.729,11.361 16.606,9.258 13.998,9.258 Z"/>
    </svg>
)

export default SvgComponent
