import {match} from '@/utils/match';

const INITIAL_STATE = {
    tasks: [],
    tasksCategories: [],
};

export default (state = INITIAL_STATE, {type, payload}) => match(type, {
    GET_TASKS: () => ({
        ...state,
        tasks: payload
    }),
    GET_TASKS_CATEGORIES: () => ({
        ...state,
        tasksCategories: payload.task_categories
    }),
}, state);