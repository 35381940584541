
import { user as types } from '@/constants/actionsNames';
// eslint-disable-next-line
import headerInterceptor from "@/utils/interseptors";
import requestRegisterFromBackend from '@/services/backend/users/register';
import requestLoginFromBackend from '@/services/backend/users/login';
import requestResetPassordFromBackend from '@/services/backend/users/resetPasswd'

export const createUser = userData => dispatch => {
    return requestRegisterFromBackend({ values: userData })
        .then(res => {
            return true;
        })
        .catch(error => {
            if (error.response.status === 400) {
                throw Error('INCORRECT_DATA');
            } else {
                throw error
            }
        })
};

export const getUser = userData => dispatch => {
    return requestLoginFromBackend({values: userData})
        .then(response => {
            const jwtDecode = parseJwt(response.data.token);
            localStorage.setItem('user', JSON.stringify({userInfo: response.data, jwtDecode: jwtDecode, isAuthenticated:true}));
            dispatch({
                type: types.GET_USER,
                payload: {data: response.data, jwtDecode: jwtDecode}
            })
        })
        .catch(error => {
            if (error.response.status === 422 ){
                throw Error('INCORRECT_DATA');
            }else{
                throw error
            }
        });
};

export const logOut = () => dispatch => {
    localStorage.removeItem('user');

    dispatch({
        type: types.LOGOUT,
    })
}

export const resetPassword = userData => dispatch => {
    return requestResetPassordFromBackend({ values: userData })
        .then(res => {
            return true;
        })
        .catch(error => {
            if (error.response.status === 422 ){
                throw Error('INCORRECT_DATA');
            }else{
                throw error
            }
        })
}
export const getUserRoles = () => dispatch => {
    if(localStorage.getItem('user')){
        let user = JSON.parse(localStorage.getItem('user'));
        let userRole = user.userInfo.role;
        return userRole;
    }
    else{
        return false;
    }
}
export const getAccessToken = () => dispatch => {
    return new Promise((resolve, reject) => {
        let authData = JSON.parse(localStorage.getItem('user'));
        if (authData && authData.userInfo.token){
            resolve(authData.userInfo.token);
        }else{
            reject("403")
        }
    });
}







function parseJwt (token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
};