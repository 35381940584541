import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import classNames from 'classnames';
import requiredValidator from '@/helpers/form/validators/required';
import emailValidator from '@/helpers/form/validators/email';
import passwordValidator from '@/helpers/form/validators/password';
import passwordConfirmationValidator from '@/helpers/form/validators/passwordConfirmation';
import FieldInput from '@/components/Fields/FieldInput/FieldInput';
import Checkbox from '@/components/Fields/FieldCheckbox/Checkbox';

import styles from './registrationForm.module.scss'

class RegistrationForm extends Component {
    render() {
        const { handleSubmit } = this.props;
        return (
            <div>
                <form className="form" onSubmit={handleSubmit}>
                    <Field
                        name="email"
                        type="text"
                        component={FieldInput}
                        formGroup
                        placeholder="E-mail"
                        field="required"
                        validate={[requiredValidator, emailValidator]}
                    />
                    <Field
                        name="password"
                        type="password"
                        component={FieldInput}
                        formGroup
                        placeholder="Hasło"
                        field="required"
                        validate={[requiredValidator, passwordValidator]}
                    />
                    <Field
                        name="password_confirmation"
                        type="password"
                        component={FieldInput}
                        formGroup
                        placeholder="Powtórz hasło"
                        field="required"
                        validate={[requiredValidator, passwordValidator, passwordConfirmationValidator]}
                    />

                    <Field
                        name="tos_1"
                        component={Checkbox}
                        label={<span>Rejestrując się akcetujesz <a href="onet.pl">Regulamin </a> oraz Politykę prywatności</span>}
                        field="required"
                        validate={[requiredValidator]}
                    />

                    <Field
                        name="tos_2"
                        component={Checkbox}
                        label="Rejestrując się akcetujesz Regulamin oraz Politykę prywatności"
                    />

                    <Field
                        name="tos_3"
                        component={Checkbox}
                        label="Rejestrując się akcetujesz Regulamin oraz Politykę prywatności"
                    />

                    <div className="clearfix">
                        <button type="submit" className={classNames('btn', 'btn-dark-pink', 'btn-block', 'btn-sm', styles.btn)}>
                            zarejestruj się
                        </button>
                    </div>
                </form>
            </div>

        );
    }
}

const Registration = reduxForm({
    form: 'RegistrationFormClass',
})(RegistrationForm);


export default Registration;
