import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from './FieldInput.module.scss'
import { FieldLabel } from '../components/FieldLabel/FieldLabel';
import renderErrors from '../utils/renderErrors';



class FieldInput extends Component {

    render() {
        const {input, placeholder, label, disabled, readonly, type, meta, error, hasFormGroupWrapper, buttonsIncDec, className, formGroup, inputCurrency, extraStyle, inlineField, colorClassName} = this.props;
        return (
            <div
                className={classNames('input',
                    {'form-group': hasFormGroupWrapper || formGroup},
                    {'is-invalid': ((meta.error && meta.touched) || error)},
                    styles.input,
                    {'inlineFieldWrapp': inlineField},
                    extraStyle,
                    colorClassName,
                    className)}
            >

                {label && (
                    <div className={classNames('clearfix', {'inlineLabel': inlineField})}>
                        <label htmlFor={input.name}><FieldLabel {...this.props}/></label>
                    </div>
                )}

                <div className={styles.inputWrapp}>
                    <div className={classNames(styles.inputBody, {[styles.inputCurrency]: inputCurrency})}>
                        <input
                            {...input}
                            id={input.name}
                            type={type}
                            disabled={disabled}
                            readOnly={readonly}
                            placeholder={placeholder}
                            className={classNames(
                                'form-control',
                                {passwordControl: type === 'password'},
                                {hasValue: meta.dirty},
                                {isInvalid: (meta.error && meta.touched) || error})}
                            autoComplete={'off'}
                        />
                        {type === 'number' && buttonsIncDec && !readonly &&  (<div className={styles.buttonsIncDec}>
                            <div onClick={() => input.value ?
                                input.onChange(parseFloat(input.value) + 1) :
                                input.onChange(1)}>
                                +
                            </div>
                            <div onClick={() => input.value > 0 ?
                                input.onChange(parseFloat(input.value) - 1) :
                                input.onChange(0)}>
                                -
                            </div>
                        </div>)}
                        {inputCurrency && <span className={styles.currency}>{inputCurrency}</span>}
                    </div>
                    {renderErrors(meta, this.props.validateMessages)}
                </div>

            </div>
        );
    }
}

export default FieldInput;

FieldInput.propTypes = {
    type: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    readonly: PropTypes.bool,
    disabled: PropTypes.bool,
    formGroup: PropTypes.bool,
    placeholder: PropTypes.string,
    colorClassName: PropTypes.oneOf(['default', 'turquise']),
    inline: PropTypes.oneOf(['default', 'inline']),
    inputCurrency: PropTypes.oneOf(['','zł', 'km', 'KM']),
    className: PropTypes.string,
    validateMessages: PropTypes.object,
    //TODO reszta swoich paramterów
}

FieldInput.defaultProps = {
    type: 'input',
    name: '',
    label: '',
    readOnly: false,
    disabled: false,
    formGroup: false,
    placeholder: '',
    className: '',
    colorClassName: 'default',
    inputCurrency: '',
    inline: 'default'
}