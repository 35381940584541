import React, {Component, Fragment} from 'react';
import {NavLink, Link} from 'react-router-dom';
import IcoPadlock from '@/icons/IcoPadlock';
import classNames from 'classnames';

import Modal from '@/components/modals/Modal';
import LoginModal from '@/components/modals/LoginModal';
import {paths} from '@/constants/config';
import LoginMenu from './Components/loginMenu';
import MobileMenu from './Components/mobileMenu';

import * as modalsActions from '@/actions/modals';
import * as userActions from '@/actions/user';

import styles from './header.module.scss';

import {bindActionCreators} from "redux";
import {connect} from "react-redux";

class Header extends Component {
    constructor(props) {
        super(props);

        this.state = {
            previousScroll: 0,
            isMenuActive: false,
            isLoginActive: false,
            isSticky: false,
            menu: false,
        }
    }

    componentDidMount() {
        this.handleScroll();
        window.addEventListener('scroll', () => this.handleScroll());
        window.addEventListener('resize', () => this.handleResize());
    }


    componentWillUnmount() {
        window.removeEventListener('scroll', () => this.handleScroll());
        window.removeEventListener('resize', () => this.handleResize());
    }

    handleResize() {
        if (window.innerWidth > 1200) {
            this.setState({
                isMenuActive: false,
                isLoginActive: false,
            })
        }
        ;
    }

    handleScroll() {
        let currentScroll = window.pageYOffset || document.documentElement.scrollTop;

        if (currentScroll > 50) {
            this.setState(prevState => {
                if (!prevState.isSticky) {
                    return {
                        isSticky: true
                    }
                }
            })
        } else {
            this.setState(prevState => {
                if (prevState.isSticky) {
                    return {
                        isSticky: false
                    }
                }
            })
        }
    }

    handleShowLoginModal(login) {
        this.props.showLoginModal(login);
    }

    handleBurgerClick() {
        this.setState(prevState => ({isMenuActive: !prevState.isMenuActive}), () => {
            if (this.state.isMenuActive && window.outerWidth < 1200) {
                document.body.classList.add('menu-open');
            } else {
                document.body.classList.remove('menu-open');
            }
        })

        this.setState({
            isLoginActive: false
        })
    }

    logOutHandler() {
        this.props.logOut();
    }

    handlePadlockClick() {
        this.setState(prevState => ({isLoginActive: !prevState.isLoginActive}), () => {
            if (this.state.isLoginActive && window.outerWidth < 1200) {
                document.body.classList.add('login-open');
            } else {
                document.body.classList.remove('login-open');
            }
        })


        this.setState({
            isMenuActive: false
        })
    }

    render() {
        const {headerXl, headerDark, loginModal, headerLight, partner, auth} = this.props;
        const {isMenuActive, isLoginActive} = this.state;
        const authValidation = auth.userInfo ? isEmpty(auth.userInfo) : true;

        return (
            <Fragment>
                <header
                    ref="header"
                    className={
                        classNames(styles.header,
                            {[styles.isSticky]: this.state.isSticky},
                            {[styles.headerDark]: headerDark},
                            {[styles.headerLight]: headerLight})
                    }
                >
                    <div className={`container ${headerXl ? 'containerXl' : '' }`}>
                        <div className={styles.headerContent}>
                            <div className="row">
                                <div className="col-xs-12 col-xl-3">
                                    <div className={styles.logo}>
                                        <Link to='/'>
                                            Vesele
                                        </Link>
                                    </div>
                                </div>
                                <div className="col">
                                    {!partner && (
                                        <nav className={classNames('d-none', 'd-xl-block', styles.navbar)}>
                                            <ul className={styles.navbarNav}>
                                                <li className={styles.navItem}>
                                                    <NavLink
                                                        exact
                                                        to={paths.ORGANIZER}
                                                        activeClassName={styles.active}
                                                    >
                                                        Organizator
                                                    </NavLink>
                                                </li>
                                                <li className={styles.navItem}>
                                                    <NavLink
                                                        exact
                                                        to={paths.OFFERS}
                                                        activeClassName={styles.active}
                                                    >
                                                        oferty
                                                    </NavLink>
                                                </li>
                                                <li className={styles.navItem}>
                                                    <NavLink
                                                        exact
                                                        to={paths.ADVERTS}
                                                        activeClassName={styles.active}
                                                    >
                                                        Ogłoszenia
                                                    </NavLink>
                                                </li>
                                                <li className={styles.navItem}>
                                                    <NavLink
                                                        exact
                                                        to={paths.INSPIRATIONS}
                                                        activeClassName={styles.active}
                                                    >
                                                        Inspiracje
                                                    </NavLink>
                                                </li>
                                            </ul>
                                        </nav>
                                    )}
                                </div>
                                <div className="col-3 d-none d-xl-block">
                                    <nav className={classNames(styles.navbar, styles.navbarReg)}>
                                        <ul className={styles.navbarNav}>
                                            {authValidation ?
                                                <Fragment>
                                                    <li className={styles.navItem}>
                                                        <Link to={{pathname: paths.REGISTRATION, state: {modal: true}}}>
                                                            Rejestracja
                                                        </Link>
                                                    </li>
                                                    <li className={styles.navItem}>
                                                        <Link to={{pathname: paths.LOGIN, state: {modal: true}}}>
                                                            Logowanie
                                                        </Link>

                                                    </li>
                                                </Fragment> :
                                                <Fragment>
                                                    <li className={styles.navItem}>
                                                    <Link to={paths.ORGANIZER_DASHBOARD_MESSAGES}>
                                                        Wiadomości
                                                    </Link>
                                                    </li>
                                                    <li className={classNames(styles.navItem, styles.dropdown)}>
                                                        <span>
                                                            {auth.userInfo.email}
                                                        </span>
                                                        <div className={styles.dropdownMenu}>
                                                            <ul className={styles.navbarNav}>
                                                                <li className={styles.navItem}>
                                                                    <Link to={paths.ORGANIZER_OFFERS}>
                                                                        Moje oferty
                                                                    </Link>
                                                                </li>
                                                                <li className={styles.navItem}>
                                                                    <Link to={paths.ORGANIZER_DASHBOARD}>
                                                                        Panel użytkownika
                                                                    </Link>
                                                                </li>
                                                                <li className={styles.navItem}>
                                                                    <span onClick={() => this.logOutHandler()}>
                                                                       Wyloguj
                                                                    </span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </li>
                                                </Fragment>

                                            }
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                            <div className="d-block d-xl-none">
                                {!partner && (
                                    <div className={classNames(styles.hamburgerWrapp, {[styles.active]: isMenuActive})}
                                         onClick={this.handleBurgerClick.bind(this)}
                                    >
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                )}

                                <div className={classNames(styles.padlockWrapp, {[styles.active]: isLoginActive})}
                                     onClick={this.handlePadlockClick.bind(this)}>
                                    <IcoPadlock/>

                                    <div className={classNames(styles.hamburgerWrapp, styles.active)}>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <LoginMenu headerDark={headerDark} headerLight={headerLight} active={isLoginActive ? true : false}/>
                    <MobileMenu headerDark={headerDark} headerLight={headerLight} active={isMenuActive ? true : false}/>
                </header>
                <header className={styles.headerHelper}></header>
                <LoginMenu headerDark partner headerLight authValidation={authValidation} active={isLoginActive ? true : false}/>
                <MobileMenu headerDark headerLight active={isMenuActive ? true : false}/>

                {loginModal.isShowing ?
                    <Modal extraClass='modalXl'>
                        <LoginModal/>
                    </Modal> :
                    null
                }

            </Fragment>
        )
    }
}

function isEmpty(obj) {
    return Object.keys(obj).length === 0;
}

const mapStateToProps = state => ({
    loginModal: state.modals.loginModal,
    auth: state.auth,
})

const mapDispatchToProps = dispatch => ({
    showLoginModal: bindActionCreators(modalsActions.showLoginModal, dispatch),
    logOut: bindActionCreators(userActions.logOut, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(Header);
