import React, {Component} from 'react';
import PropTypes from 'prop-types';

import renderErrors from '../utils/renderErrors';
import styles from './checkbox.module.scss';
import IcoCheck from '@/icons/IcoCheck';


class Checkbox extends Component {
    render() {
        const {input, label, checkMarkColor, meta } = this.props;
        return (
            <div className={styles.labelContainer}>
                <label className={this.props.className}>
                    {label}
                    <input
                        {...input}
                        id={input.name}
                        type="checkbox"
                        value={input.name}
                    />
                    <span className={`checkmark ${checkMarkColor}`}>
                        {this.props.hasCheckIcon && <IcoCheck />}
                    </span>
                </label>
                {renderErrors(meta, this.props.validateMessages)}
            </div>
        );
    }
}

export default Checkbox;

Checkbox.propTypes = {
    name: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    className: PropTypes.oneOf(['default', 'checkboxOrganizer', 'checkboxAddGuest', 'checkboxWithoutLabel', 'partner']),
    checkMarkColor: PropTypes.oneOf(['', 'white']),
    hasCheckIcon: PropTypes.bool,
    validateMessages: PropTypes.object,
}

Checkbox.defaultProps = {
    name: '',
    label: '',
    className: 'default',
    checkMarkColor: '',
    hasCheckIcon: false,
}