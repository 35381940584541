import {createConstants} from '@/utils/createConstants';

export const user = createConstants(
    'CREATE_USER',
    'GET_USER',
    'GET_USER_DETAILS',
    'GET_USER_DETAILS_SUCCESS',
    'LOGOUT',
);

export const modals = createConstants(
    'SHOW_LOGIN_MODAL',
    'HIDE_LOGIN_MODAL',
    'SHOW_REGISTRATION_MODAL',
    'HIDE_REGISTRATION_MODAL',
    'SHOW_ADDGUEST_MODAL',
    'HIDE_ADDGUEST_MODAL',
    'SHOW_ADDGUESTGROUP_MODAL',
    'HIDE_ADDGUESTGROUP_MODAL',
    'SHOW_EDITWEDDING_MODAL',
    'HIDE_EDITWEDDING_MODAL'
)

export const program = createConstants(
    'FETCH_PROGRAM',
    'FETCH_PROGRAM_FILTERS',
    'UPDATE_ACTIVE_FILTERS',
    'CLEAR_ACTIVE_FILTERS',
    'ADD_TO_CALENDAR'
)


export const guest = createConstants(
    'FETCH_GUEST',
    'FETCH_GUESTS',
    'FETCH_GUEST_CATEGORIES',
    'CREATE_GUEST',
    'ADD_GUEST',
    'DELETE_GUEST',
    'FETCH_GUESTS_INFO'
);

export const offers = createConstants(
    'GET_OFFERS',
    'GET_CATEGORIES',
    'GET_CATEGORIES_SUCCESS'
);

export const tasks = createConstants(
    'GET_TASKS',
    'GET_TASKS_SUCCESS',
    'DEL_TASKS_SUCCESS',
    'GET_TASKS_CATEGORIES',
);

export const budget = createConstants(
    'FETCH_BUDGET_CATEGORIES',
    'SHOW_NEW_BUDGET_CATEGORY',
    'HIDE_NEW_BUDGET_CATEGORY',
    'FETCH_BUDGET_STATS',
    'POST_BUDGET',
    'PUT_BUDGET',
    'DEL_BUDGET_',
);

export const wedding = createConstants(
    'FETCH_WEDDING',
    'FETCH_MAP_POINTS',
);