import axios from 'axios';
import { API_URL } from '@/constants/config';

export default ({ values }) => {
    return axios({
        method: 'POST',
        url: `${API_URL}/api/v1/sessions`,
        data: values
    });
}
