export const API_URL = process.env.REACT_APP_API_URL;
export const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
export const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID;

export const paths = {
    HOME: '/',
    REGISTRATION: '/registration',
    LOGIN: '/login',
    RESET_PASSWORD:'/resetpassword',
    NEW_PASSWORD:'/newpassword',
    INSPIRATIONS: '/inspiracje',
    INSPIRATION: '/inspiracje/inspiracja',
    CATEGORYINSPIRATION: '/kategoriainspiracje',
    SINGLE_OFFER_PRO: '/oferty/oferta-pro',
    SINGLE_OFFER_STANDARD: '/oferty/oferta-standard',
    OFFERS: '/oferty',
    ADVERT: '/ogloszenia/ogloszenie',
    ADVERTS: '/ogloszenia',
    SITE: '/sluby',
    SITE_ID: id => `/sluby/${id}`,
    ORGANIZER: '/organizator',

    ORGANIZER_SITE_EDIT: `/organizer/wedding`,
    ORGANIZER_DASHBOARD: '/zalogowany/organizator/dashboard', //zapinane
    ORGANIZER_GUESTS: '/zalogowany/organizator/lista-gosci', //lista gosci
    ORGANIZER_SAVED_OFFERS_CATEGORIES: '/zalogowany/organizator/zapisane-oferty', //wpięte
    ORGANIZER_ID: id => `/organizator/${id}`,
    ORGANIZER_DASHBOARD_MESSAGES: '/zalogowany/organizator/dashboard/messages',
    ORGANIZER_DASHBOARD_MESSAGE_MOBILE: id => `/zalogowany/organizator/dashboard/messages/${id}`,
    ORGANIZER_SAVED_OFFERS_CATEGORY_SINGLE: id => `/zalogowany/organizator/zapisane-oferty/${id}`,
    ORGANIZER_TASKS_LIST: '/zalogowany/organizator/lista-zadan', //wpięte
    ORGANIZER_OFFERS: '/zalogowany/organizator/moje-oferty',
    ORGANIZER_EDIT_OFFER: id => `/zalogowany/organizator/moje-oferty/${id}`,
    ORGANIZER_ADD_OFFER: '/zalogowany/organizator/dodaj-oferte',
    ORGANIZER_BUDGET: '/zalogowany/organizator/budzet',
    ORGANIZER_GUESTS_INVITATIONS: '/zalogowany/organizator/lista-gosci/zaproszenia',
    ORGANIZER_GUESTS_REMINDERS: '/zalogowany/organizator/lista-gosci/przypomnienia',

    PARTNER_LOGIN: '/partner/login',
    PARTNER_DASHBOARD: '/partner/dashboard',
    PARTNER_DASHBOARD_MESSAGES: '/partner/dashboard/messages',
    PARTNER_DASHBOARD_MESSAGE_MOBILE: id => `/partner/dashboard/messages/${id}`,
    PARTNER_ADD_OFFER: '/partner/addoffer',
    PARTNER_CHANGE_TO_PRO: '/partner/changetopro',
    PARTNER: '/partner',
    PARTNER_SETTINGS: '/partner/settings',
    PARTNER_OFFERS: '/partner/offers',
    PARTNER_OFFERS_PRO: id => `/partner/offers/pro/${id}`,
    PARTNER_OFFERS_STANDARD: id => `/partner/offers/standard/${id}`,
};

export const VALIDATION_MESSAGES = {
    maxLength: (max) => `Must be ${max} characters or less`,
    email: 'Podany email jest błedny',
    required: 'To pole jest wymagane',
    password: 'Hasło musi mieć od 3 do 16 znaków, składać się z dużych i małych liter oraz zawierać znak specjalny',
	passwordConfirmation: 'Hasła nie są identyczne',
    default: 'Invalid field'
}

export const FRONT_DATE_FORMAT = 'DD-MM-YYYY'
export const FRONT_DATE_TIME_FORMAT = 'DD-MM-YYYY HH:mm'
export const FRONT_TIME_FORMAT = 'HH:mm'