import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import classNames from 'classnames';
import FieldInput from '@/components/Fields/FieldInput/FieldInput';
import requiredValidator from '@/helpers/form/validators/required';
import passwordValidator from '@/helpers/form/validators/password';
import passwordConfirmationValidator from '@/helpers/form/validators/passwordConfirmation';
import queryString from 'query-string';

import styles from './newPasswdForm.module.scss'

class resetPasswordForm extends Component {
    constructor(props) {
        super(props);
        const queryParams = queryString.parse(this.props.location.search);
        this.token = queryParams.token ? queryParams.token : false;
        this.email = queryParams.email ? queryParams.email : false;
        console.log(this.token)
        console.log(this.email)
        this.state = {
            isFetching: (this.token && this.email),
            isShowingSuccessAlert: false,
            isShowingServerErrorAlert: false,
            isShowingTokenAlert: (!this.token || !this.email),
        }
    }

    render() {
        const { handleSubmit } = this.props;
        return (
            <div>
                <form className="form" onSubmit={handleSubmit}>
                    <Field
                        name="password"
                        type="password"
                        component={FieldInput}
                        formGroup
                        placeholder="Hasło"
                        field="required"
                        validate={[requiredValidator, passwordValidator]}
                    />
                    <Field
                        name="password_confirmation"
                        type="password"
                        component={FieldInput}
                        formGroup
                        placeholder="Powtórz hasło"
                        field="required"
                        validate={[requiredValidator, passwordValidator, passwordConfirmationValidator]}
                    />
                    <div className="clearfix">
                        <button type="submit" className={classNames('btn', 'btn-dark-pink', 'btn-block', 'btn-sm', styles.btn)}>
                            Ustaw nowe hasło
                        </button>
                    </div>
                </form>
            </div>

        );
    }
}

const NewPasswdForm = reduxForm({
    form: 'newPasswordFormClass',
})(resetPasswordForm);


export default NewPasswdForm;
