import React, { Component, Fragment } from 'react'
import {Link} from 'react-router-dom';
import {paths} from '@/constants/config';
import classNames from 'classnames';

import Header from '@/components/html/Header';
import Preloader from '@/components/html/Preloader';
import Footer from '@/components/html/Footer';
import styles from './unauthorized.module.scss';


class Unauthorized extends Component {
    handleShowLoginModal() {
        this.props.showLoginModal();
    }
    render() {
        return (
            <Fragment>
                <Preloader />
                <Header headerLight={true} />
                    <main className={styles.organizerPage}>
                        <section className={classNames(styles.section, styles.sectionTop)}>
                            <div className="container">
                                <h1 className={styles.header}>
                                    Musisz być zalogowany
                                </h1>
                                <Link to={paths.REGISTRATION} className={classNames('btn', 'btn-dark-pink')}>
                                    Rozpocznij planowanie
                                </Link>
                                <div className={styles.moreDetailsWrapp}>
                                    <span className={styles.moreDetails}>
                                    Masz już konto? 
                                        <Link to={paths.LOGIN}>
                                            <span className={styles.strong}>
                                                Zaloguj się
                                            </span>
                                        </Link>
                                    </span>
                                </div>
                            </div>
                        </section>
                    </main>
                <Footer />
            </Fragment>
        )
    }
}


export default Unauthorized;