import axios from 'axios';
import store from '@/store';

const headerInterceptor = axios.interceptors.request.use(request => {
    const storeState = store.getState();
    if (storeState != null && 'auth' in storeState) {

        if ('userInfo' in storeState.auth && storeState.auth.userInfo != null && 'jwt' in storeState.auth.userInfo) {
            const authorization = storeState.auth.userInfo.jwt;
            request.headers.Authorization = `Bearer ${authorization}`;
        }

        // request.headers.stamp = stamp;

        return request;
    }
});

export default headerInterceptor;

