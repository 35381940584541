import {combineReducers} from 'redux';
import {reducer as FormReducer} from 'redux-form';
import modalsReducer from './reducerModals'
import userReducer from './reducerUser'
import guestReducer from './organizator/reducerGuests'
import usersOffersReducer from './organizator/reducerUsersOffers'
import usersTasksReducer from './organizator/reducerUsersTasks'
import usersBudgetReducer from './organizator/reducerUsersBudget'
import reducerWedding from './organizator/reducerUsersWedding'

const rootReducer = combineReducers({
    auth: userReducer,
    form: FormReducer,
    modals: modalsReducer,
    guest: guestReducer,
    usersOffers: usersOffersReducer,
    usersTasks: usersTasksReducer,
    userBudget: usersBudgetReducer,
    wedding: reducerWedding
});

export default rootReducer;
