import React from "react"

const SvgComponent = () => (
	<svg viewBox="0 0 28 20">
		<g data-name="Warstwa 2">
			<path
				fill="none"
				stroke="#424a60"
				strokeLinecap="round"
				strokeLinejoin="round"
				strokeWidth={2}
				d="M27 1L11 19 1 11"
				data-name="Capa 1"
			/>
		</g>
	</svg>
)

export default SvgComponent
