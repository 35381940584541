import React from "react"

const SvgComponent = props => (
    <svg
        width="15px" height="29px">
        <path fillRule="evenodd"  fill="rgb(48, 48, 48)"
              d="M14.446,0.004 L10.848,-0.003 C6.805,-0.003 4.194,2.798 4.194,7.132 L4.194,10.421 L0.576,10.421 C0.263,10.421 0.010,10.685 0.010,11.012 L0.010,15.778 C0.010,16.105 0.263,16.369 0.576,16.369 L4.193,16.369 L4.193,28.395 C4.193,28.721 4.447,28.986 4.759,28.986 L9.480,28.986 C9.792,28.986 10.045,28.721 10.045,28.395 L10.045,16.369 L14.275,16.369 C14.588,16.369 14.840,16.105 14.840,15.778 L14.842,11.012 C14.842,10.856 14.783,10.705 14.677,10.594 C14.571,10.483 14.427,10.421 14.276,10.421 L10.046,10.421 L10.046,7.633 C10.046,6.292 10.351,5.612 12.022,5.612 L14.446,5.611 C14.758,5.611 15.011,5.346 15.011,5.020 L15.011,0.595 C15.011,0.268 14.758,0.004 14.446,0.004 Z"/>
    </svg>
)

export default SvgComponent
