import React, {Component, Fragment} from 'react';
import {NavLink, Link, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import {paths} from '@/constants/config';
import LoginForm from './Components/loginForm';
import RegistrationForm from './Components/registrationForm';
import ResetPassword from './Components/resetForm';
import NewPasswdForm from './Components/newPasswdForm';
import {bindActionCreators} from 'redux';
import classNames from 'classnames';
import styles from './loginModal.module.scss';
import IcoClose from '@/icons/IcoClose';
import Preloader from '@/components/html/Preloader'

import {history} from "@/store";

import * as userActions from '@/actions/user'


class LoginModal extends Component {
    state = {
        redirect: false,
        redirectTo: '',
        incorrectLoginData: false,
        succesfullReset: false,
        incorrectResetEmail: false,
        isFetching: false,
    };

    renderRedirect = () => {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirectTo}/>
        }
    };

    handleClose = (currentLocation, previousLocation) => {
        let redirectTo;
        previousLocation === paths.LOGIN || previousLocation === paths.REGISTRATION ?
            redirectTo = '/' :
            redirectTo = previousLocation;

        this.setState({
            redirect: true,
            redirectTo: redirectTo,
        })
    };

    submitLogin = (values) => {
        this.setState({
            incorrectLoginData: false,
            isFetching: true,
        });

        this.props.loginUser(values).then(status => {
            this.handleClose(this.props.currentLocation, this.props.previousLocation)
            history.push('/')
        }).catch(error => {
            if (error.message === 'INCORRECT_DATA') {
                this.setState({
                    incorrectLoginData: true,
                    isFetching: false,
                })
            } else {
                this.setState(() => {
                    throw error
                })
                this.setState({isFetching: false})
            }
        })
    };

    submitRegistration = (values) => {
        let tvalues = {user: {...values}}
        this.props.registrationUser(tvalues).then(status => {
                this.setState({
                    redirect: true,
                    redirectTo: paths.LOGIN,
                })
            }
        ).catch(error => {
            this.setState(() => {
                throw error
            })
        })

    }
    submitReset = (values) => {
        this.setState({
            isFetching: true
        })
        this.props.resetPassword(values).then(status => {
            this.setState({
                succesfullReset: true,
                incorrectResetEmail: false,
                isFetching: false
            })
        }).catch(error => {
            if (error.message === 'INCORRECT_DATA') {
                this.setState({
                    incorrectResetEmail: true,
                    isFetching: false
                })
            } else {
                this.setState(() => {
                    throw error
                })
            }
        })
    }

    submitNewPasswd = (values) => {
        console.log(values)
    }

    componentDidMount() {
        document.body.classList.add('modal-open');
    }

    componentWillUnmount() {
        document.body.classList.remove('modal-open');
    }

    renderContent() {
        if (this.props.login) {
            return (
                <Fragment>
                    {this.state.incorrectLoginData && (
                        <div className="alert alert-warning">Nieprawidłowy login lub hasło</div>
                    )}
                    <LoginForm
                        onSubmit={this.submitLogin.bind(this)}
                    />
                    <div className={styles.more}>
                        <p className="text-center">
                            Nie posiadzasz konta?
                            <Link
                                to={{
                                    pathname: '/registration',
                                    state: {modal: true}
                                }}
                            > Zarejestruj się
                            </Link>
                        </p>
                    </div>
                </Fragment>
            )
        } else if (this.props.reset) {
            return (
                <Fragment>
                    {this.state.incorrectResetEmail && (
                        <div className="alert alert-warning">Nie ma takiego adresu email w naszej bazie</div>
                    )}
                    {this.state.succesfullReset && (
                        <div className="alert alert-success">Na wybrany adres email został wysłany link resetujący
                            hasło.</div>
                    )}
                    <ResetPassword
                        onSubmit={this.submitReset.bind(this)}
                    />
                </Fragment>
            )
        } else if (this.props.newPassword) {
            return (
                <Fragment>
                    <NewPasswdForm
                        onSubmit={this.submitNewPasswd.bind(this)}
                    />
                </Fragment>
            )
        } else {
            return (
                <Fragment>
                    <RegistrationForm
                        onSubmit={this.submitRegistration.bind(this)}
                    />
                    <div className={styles.more}>
                        <p className="text-center">
                            Posiadasz konto?
                            <Link
                                to={{
                                    pathname: '/login',
                                    state: {modal: true}
                                }}
                            > Zaloguj się
                            </Link>
                        </p>
                    </div>
                </Fragment>
            )
        }
    }

    render() {

        const {currentLocation, previousLocation} = this.props;
        const {isFetching} = this.state;

        const loginModal = this.props.login;
        console.log(isFetching)
        return (
            <Fragment>

                {this.renderRedirect()}
                <Preloader active={isFetching}/>
                <div className={`modal fade show modal-active  ${this.props.extraClass}`}>
                    <div className={classNames('modal-dialog', 'active', styles.loginModal)} role="document">
                        <div className={classNames('modal-content', styles.modalContent)}>
                            <div className={styles["container"]}>
                                <div className={styles.leftWrapp}>
                                    <div className={classNames(styles.content, styles.contentWrapp)}>
                                        <div className={classNames(styles.contentSwitch, {[styles.left]: !loginModal})}>
                                            <div className={styles.translateWrapp}>
                                                <div className={styles.translateBody}>
                                                    <div className={styles.contentTranslate}>
                                                        <h1 className={styles.h1}>
                                                            Zaloguj się<br/>
                                                            na swoje konto
                                                        </h1>
                                                        <p className={styles.p}>
                                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                            Maecenas eu ornare odio, in lacinia mi. In hac habita
                                                        </p>
                                                    </div>
                                                    <div className={styles.contentTranslate}>
                                                        <h1 className={styles.h1}>
                                                            Zacznij planować<br/>
                                                            już teraz
                                                        </h1>
                                                        <p className={styles.p}>
                                                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                                            Maecenas eu ornare odio, in lacinia mi. In hac habita
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.rightWrapp}>
                                    <div className={styles.content}>
                                        <button
                                            type="button"
                                            className={classNames('btnClose', styles.btnClose)}
                                            onClick={() => this.handleClose(currentLocation, previousLocation)}
                                        >
                                            <IcoClose/>
                                        </button>
                                        <div className={styles.headerWrapp}>
                                            <h1 className={styles.h1}>
                                                <NavLink
                                                    to={{
                                                        pathname: '/login',
                                                        state: {modal: true}
                                                    }}
                                                    activeClassName={styles.active}
                                                >
                                                    Logowanie
                                                </NavLink>
                                                <NavLink
                                                    to={{
                                                        pathname: '/registration',
                                                        state: {modal: true}
                                                    }}
                                                    activeClassName={styles.active}
                                                >
                                                    Rejestracja
                                                </NavLink>
                                            </h1>

                                            <div className={styles.contentSwitch}>
                                                {this.renderContent()}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Fragment>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    loginUser: bindActionCreators(userActions.getUser, dispatch),
    registrationUser: bindActionCreators(userActions.createUser, dispatch),
    resetPassword: bindActionCreators(userActions.resetPassword, dispatch),
})

export default connect(null, mapDispatchToProps)(LoginModal);
