import React, { Component } from 'react';
import { Field, reduxForm } from 'redux-form';
import classNames from 'classnames';
import {NavLink} from 'react-router-dom';
// import validate from '../../../../shared/helpers/validations/admin/createNewCompanyExternal';
import FieldInput from '@/components/Fields/FieldInput/FieldInput';
import requiredValidator from '@/helpers/form/validators/required';
import emailValidator from '@/helpers/form/validators/email';

import styles from './loginForm.module.scss'

class LoginForm extends Component {
    render() {
        const { handleSubmit } = this.props;
        return (
            <div>
                <form className="form" onSubmit={handleSubmit}>
                    <Field
                        name="email"
                        type="text"
                        component={FieldInput}
                        placeholder="E-mail"
                        formGroup
                        field="required"
                        validate={[requiredValidator, emailValidator]}
                    />
                    <Field
                        name="password"
                        type="password"
                        component={FieldInput}
                        placeholder="Hasło"
                        formGroup
                        field="required"
                        validate={[requiredValidator]}
                    />

                    <div className={classNames('text-center', styles.more)}>
                        <NavLink
                            to={{
                                pathname: '/resetpassword',
                                state: {modal: true}
                            }}
                            activeClassName={styles.active}
                        >
                            Nie pamiętasz hasła?
                        </NavLink>
                        
                    </div>

                    <div className="clearfix">
                        <button type="submit" className={classNames('btn', 'btn-dark-pink', 'btn-block', 'btn-sm', styles.btn)}>
                            zaloguj się
                        </button>
                    </div>
                </form>
            </div>

        );
    }
}

const Registration = reduxForm({
    form: 'RegistrationFormClass',
})(LoginForm);


export default Registration;
